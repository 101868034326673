<!--信息审核-监测点信息采集审核-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-select
            v-model="filter.status"
            placeholder="审核状态"
            clearable
            @keyup.native.enter="getData"
            @change="getData"
          >
            <el-option :value="0" label="待审核"/>
            <el-option :value="1" label="审核通过"/>
            <el-option :value="2" label="审核未通过"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="检测点名称"
            @change="getData"
            v-model="filter.name"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
          <el-button type="primary" @click="openExportSizeInput">导出</el-button>
          <el-button type="primary" @click="openBatchImport">批量导入</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight"
        style="width: 100%"
        :key="itemKey"
      >
        <el-table-column
          type="selection"
          :selectable="selectable"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          label="监测点"
          width="140"
          show-overflow-tooltip
          header-align="center">
          <template slot-scope="scope">
            <div slot="reference">
              <el-tag size="mini">{{ scope.row.name }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="addr"
          label="详细地址"
          width="240"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="user_name"
          label="提交人"
          show-overflow-tooltip
          header-align="center"
          align="center"
        />
        <el-table-column
          prop="create_at"
          width="160"
          label="提交时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="status"
          label="审核状态"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.status === 0">待审核</el-tag>
            <el-tag type="success" v-else-if="scope.row.status === 1 || scope.row.status ===3 ">通过</el-tag>
            <el-tag type="danger" v-else>未通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="handle_name"
          label="审核人"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="remark"
          label="审核备注"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="status"
          label="是否已创建档案"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 3">已创建</div>
            <div v-else>未创建</div>
          </template>

        </el-table-column>
        <el-table-column
          prop="cuisine_name"
          label="菜系"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="fan_speed"
          label="风速"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="fan_quantity"
          label="风量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="pipe_area"
          label="管道截面面积"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="stove_num"
          label="灶头数量"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="stove_area"
          label="集气灶面积"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="exhaust_time"
          label="日均排烟时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="area_name"
          show-overflow-tooltip
          label="所属地区"
          align="center"
          width="240"
          header-align="center"
        />
        <el-table-column
          prop="owner_name"
          show-overflow-tooltip
          label="所属单位"
          align="center"
          width="240"
          header-align="center"
        />
        <el-table-column
          prop="lng"
          label="经度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="lat"
          label="纬度"
          width="96"
          align="center"
          header-align="center"
        />
        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              title="查看"
              @click="handleView(scope.row)"
              class="opt el-icon-view"
            />
            <i
              title="导出"
              @click="handleRecordExport(scope.row)"
              class="opt el-icon-download"
            />
            <i
              title="编辑"
              v-if="scope.row.status === 0"
              @click="handleEdit(scope.row)"
              class="opt el-icon-edit"
            />
            <i
              title="删除"
              @click="handleDel(scope.row)"
              class="opt el-icon-delete"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="审核"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              :type="scope.row.status ===0? 'primary':'info'"
              :disabled="scope.row.status !==0"
              @click="handleVerify(scope.row)">
              审核
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="primary"
        size="mini"
        :disabled="sels.length === 0"
        @click="handleBatchVerify">
        批量创建
      </el-button>
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <LocaleEditDialog
      :form-data="formData"
      :visible="dlg.visible"
      :title="dlg.title"
      :state="dlg.state"
      :is-view="isView"
      :cuisine="cuisine"
      :managementTypes="managementTypes"
      @handleClose="handleClose"/>

    <locale-verify-batch-import-dialog
      :visible="batchImportVisible"
      @handleClose="handleImportClose"
    />


  </section>
</template>

<script>
import {mapState} from 'vuex'
import {dateFormater, base64ToBlob, ownerJoin, getPname, treeFilter} from '@/util/index'
import notificationDialog from '@/component/NotificationDialog'
import {
  getSinglePname,
  loadData,
  getUserInfo,
  join,
  toIntArray,ownerFilter
} from '@/util/index'
import conf from '@/config'
import LocaleEditDialog from "@/component/LocaleVerifyEditDialog.vue";
import LocaleVerifyBatchImportDialog from "@/component/LocaleVerifyBatchImportDialog.vue";

export default {
  name: 'LocaleVerify',
  components: {LocaleVerifyBatchImportDialog, LocaleEditDialog, notificationDialog},
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      datalist: {},
      userInfo: {},
      cuisine: [],
      cuisineList: [],
      managementTypes: [],
      managementList: [],
      exportList: [],
      showImg: false,
      maintainer: [],
      org: null,
      fileList: [],
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      filter: {
        status: null,
        owner: null,
        name: null,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      filterRecord: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20
      },
      localeId: '',
      baseInfo: {},
      recordVisible: false,
      recordlist: {},
      formData: {},
      formRule: {
        Name: [{required: true, message: '名称不可为空', trigger: 'blur'}],
        Lng: [{required: true, message: '经度不可为空', trigger: 'blur'}],
        Lat: [{required: true, message: '纬度不可为空', trigger: 'blur'}]
      },
      DeviceNum: null,
      itemKey: 1,
      isView: false, //是否查看,
      batchImportVisible: false,    //批量导入窗口
    }
  },
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'customerTree', 'customer']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org'}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  mounted() {
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.$get('admin/listAllCuisine').then((res) => {
      this.cuisineList = res
      var data = []
      var keys = []
      for (var i = 0; i < res.length; i++) {
        var index = keys.indexOf(res[i].ResourceName)
        if (index == -1) {
          keys.push(res[i].ResourceName)
          data.push({
            value: res[i].ResourceName,
            label: res[i].ResourceName,
            children:[{
              value: res[i].Id,
              label: res[i].Name,
            }]
          })
        } else {
          data[index].children.push({
            value: res[i].Id,
            label: res[i].Name,
          })
        }
      }
      this.cuisine = data
    })
    this.$get('admin/management').then((res) => {
      var data = []
      var keys = []
      this.managementList = res.list
      for (var i = 0; i < res.list.length; i++) {
        var index = keys.indexOf(res.list[i].type)
        if (index == -1) {
          keys.push(res.list[i].type)
          data.push({
            value: res.list[i].type == 1?"经营性":"非经营性",
            label: res.list[i].type == 1?"经营性":"非经营性",
            children:[{
              value: res.list[i].id,
              label: res.list[i].name,
            }]
          })
        } else {
          data[index].children.push({
            value: res.list[i].id,
            label: res.list[i].name,
          })
        }
      }
      this.managementTypes = data
    })
    // 更新所属单位（不排除未来需要更新更多数据
    if (this.customerTree.length === 0) {
      loadData(this, null, 1)
    }
    if (this.areaTree.length === 0) {
      this.$get('admin/listBasicDataOfArea').then(res => {
        this.$store.dispatch('setArea', res.Area)
      })
    }
    this.getData()
  },
  methods: {
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleExport(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleExport(num) {
      const param = {
        page: this.filter.page,
        size: num,
        is_download: true
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        const owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
        param.owner = owner
      }
      if (this.filter.status !== "" && this.filter.status !== null) {
        param.status = this.filter.status
      }
      if (this.filter.name) {
        param.name = this.filter.name
      }
      this.$get("admin/locale_exam", param).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    },

    getData() {
      this.loading = true
      const param = {
        page: this.filter.page,
        size: this.filter.size,
        Param: {}
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        param.owner = `${ownerJoin(this.filter.owner, this.userInfo)}`
      }
      if (this.filter.status !== null && this.filter.status !== "") {
        param.status = this.filter.status
      }
      if (this.filter.name) {
        param.name = this.filter.name
      }
      this.$get("admin/locale_exam", param).then(res => {
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.itemKey = Math.random()
        this.loading = false
      })
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },

    handleBeforeRemove(file) {
      return this.$confirm('确认删除吗？').then(() => {
        const reg = /res\/(.*)\.[a-zA-Z]+$/
        this.$get('admin/delLocaleAt', {fid: file.url.match(reg)[1]})
      })
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    linkFormatter(r, c, v) {
      return v === 1 ? '是' : '否'
    },

    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.val = null
        this.filter.owner = null
        this.filter.area = null
      }
      loadData(
        this,
        (res) => {
          this.$store.dispatch('setLocale', res).then(() => {
            this.getData()
          })
        },
        3
      )
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd() {
      this.dlg.title = '新增'
      this.fileList = []
      this.formData = {}
      this.dlg.visible = true
      this.dlg.state = true
    },
    handleView(row) {
      this.fileList = []
      this.dlg.title = '查看'
      this.dlg.visible = true
      this.isView = true
      let userInfo = getUserInfo().Info
      this.$get('admin/locale_exam/info/' + row.id).then((res) => {
        this.formData = res
        this.formData.owner = ownerFilter(this.formData.owner, userInfo)
        this.formData.area_id = toIntArray(res.area_id)
        for(var i = 0; i < this.cuisineList.length; i++) {
          if (this.formData.cuisine == this.cuisineList[i].Id) {
            this.formData.cuisine = [this.cuisineList[i].ResourceName, this.cuisineList[i].Id]
            break
          }
        }
        for(var i = 0; i < this.managementList.length; i++) {
          if (this.formData.management_types == this.managementList[i].id) {
            this.$set(this.formData, 'management_types', [this.managementList[i].type == 1 ? "经营性":"非经营性", this.managementList[i].id])
            break
          }
        }
      })
      
    },
    handleEdit(row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.dlg.state = false
      this.isView = false
      this.fileList = []
      let userInfo = getUserInfo().Info
      this.$get('admin/locale_exam/info/' + row.id).then((res) => {
        this.formData = res
        this.formData.owner = ownerFilter(this.formData.owner, userInfo)
        this.formData.area_id = toIntArray(res.area_id)
        for(var i = 0; i < this.cuisineList.length; i++) {
          if (this.formData.cuisine == this.cuisineList[i].Id) {
            this.formData.cuisine = [this.cuisineList[i].ResourceName, this.cuisineList[i].Id]
            break
          }
        }
        for(var i = 0; i < this.managementList.length; i++) {
          if (this.formData.management_types == this.managementList[i].id) {
            this.$set(this.formData, 'management_types',[this.managementList[i].type == 1 ? "经营性":"非经营性", this.managementList[i].id])
            break
          }
        }
      })
    },
    handleVerify(row) {
      this.fileList = []
      this.dlg.title = '审核'
      this.dlg.visible = true
      this.isView = true
      let userInfo = getUserInfo().Info
      this.$get('admin/locale_exam/info/' + row.id).then((res) => {
        this.formData = res
        if (userInfo !== null && userInfo.pid !== "") {
          this.formData.owner = this.formData.owner.replace(userInfo.pid, "/")
        }
        this.formData.owner = toIntArray(res.owner)
        this.formData.area_id = toIntArray(res.area_id)
        this.formData.status = 0
        for(var i = 0; i < this.cuisineList.length; i++) {
          if (this.formData.cuisine == this.cuisineList[i].Id) {
            this.formData.cuisine = [this.cuisineList[i].ResourceName, this.cuisineList[i].Id]
            break
          }
        }
        for(var i = 0; i < this.managementList.length; i++) {
          if (this.formData.management_types == this.managementList[i].id) {
            this.$set(this.formData, 'management_types',[this.managementList[i].type == 1 ? "经营性":"非经营性", this.managementList[i].id])
            break
          }
        }
      })
    },
    handleBatchVerify() {
      this.$confirm('是否确定批量创建?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        this.sels.forEach((item) => {
          ids.push(item.id)
        })
        let form = new FormData()
        form.append('ids', ids)
        this.$post('admin/locale_exam/update', form).then(res => {
          this.$message({
            message: '创建成功',
            type: 'success'
          });
          this.getData()
        })
      })
    },
    handleDel(row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$post('admin/locale_exam/delete/' + row.id)
            .then(() => {
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.getData()
            })
        })
    },
    handleSubmit() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              para.Owner = join(para.Owner)
              para.AreaId = join(para.AreaId)
              para.LocalePicS = this.fileList
              if (this.dlg.state) {
                if (this.org !== '') {
                  para.Name = this.org + '-' + para.Name
                } else {
                  para.Name = this.userInfo['org'] + '-' + para.Name
                }
              }
              this.$post('admin/saveLocale', para)
                .then(() => {
                  this.dlg.visible = false
                })
                .catch(() => {
                })
            })
            .catch(() => {
            })
        }
      })
    },
    handleClose() {
      this.dlg.visible = false
      this.isView = false
      this.getData()
    },
    handleImportClose() {
      this.batchImportVisible = false
      this.getData()
    },
    selectable(row) {
      return row.status === 1;
    },
    openBatchImport() {
      this.batchImportVisible = true
    },
    handleRecordExport(row) {
      this.$get('admin/locale_exam/download/'+ row.id).then(res=>{
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
    }

  }
}
</script>

<style lang="scss" scoped>

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 160px;
  height: 160px;
}

.view-img {
  width: 178px;
  height: 178px;
  margin-right: 10px;
}

.remove-img {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  background: rgba(0, 0, 0, .5);
  transition: transform .3s;
  -webkit-transition: transform .3s;
}

.remove-img:hover {
  cursor: pointer;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.locale-progress {
  margin-top: 12px;
  margin-right: 40px;
}
</style>
